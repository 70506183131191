*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}

html {
  font-size: $rem-base;
  // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
  font-size: calc(16px + 6 * ((100vw - 320px) / 448));
  scroll-behavior: smooth;
}

body {
  background-color: $light;
  color: $dark;
  font-family: heebo, serif;
  font-variation-settings: 'wght' $normal;
  line-height: 1.75;
  margin: 0;
  padding: 0;
  strong {
    background-color: rgba($secondary, 0.1);
  }
}

main {
  margin: 0 auto;
  max-width: 768px;
  padding: 10px;
}

.g-recaptcha {
  display: none;
}

.small-text {
  @include remCalc(2);
}

.italic-text {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
}

a {
  color: $highlight;
}

ul {
  list-style-type: none;
  margin: 0;
  li {
    position: relative;
    &:after {
      background-color: $dark;
      border-radius: 4px;
      content: '';
      height: 4px;
      left: -1rem;
      position: absolute;
      top: 0.7rem;
      width: 4px;
    }
  }
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

// >= 768
@media (min-width: 48em) {
  html {
    font-size: 22px;
  }
}

