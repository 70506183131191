/**
 * FONTS
 */
@font-face {
  font-family: heebo;
  src: url("/app/ui/fonts/Heebo-VariableFont_wght.woff2") format("woff2-variations"); }

/**
 * Colors
 */
/**
 *
 * @mixin simple-grid();
 *
 * A very basic grid for very basic grid needs. Intended to replace float grids.
 * 
 * Takes 5 arguements:
 * $cols    Columns, can be any value 'grid-template-columns' accepts. Default: 1fr
 * $rows    Rows, can be any value 'grid-auto-rows' allows. Default: auto
                NOTE: 'auto' will cause all cells, in all rows, to be equal height
 * $gutter  Gutter, can be any value 'gap' allows. Default: 1rem
 * $margin  Margin, the outer margin of the grid. Can be any value(s) 'margin' accepts. Default: 0
 * $width   Width, can be any value 'width' allows. Default: 100%
 *
 * Example: simple-grid(1fr 1fr 1fr, auto, 5px, 1em, 768px)
 *
 */
/**
 *
 * @mixin simple-flex();
 *
 * A very basic flex container to align elements in a box.
 *
 * $align   Vertical alignment, accepts any value 'align-items' accepts. Default: center
 * $justify Horizontal alignment, accepts any value 'justify-content' accepts. Default: center
 *
 * Example: simple-flex(center, center)
 *
 */
/**
 * @mixin remCalc();
 * 
 * Calculate font size based on defined $rem-base and $font-step variable in _settings.scss
 *
 * Takes one argument. The number you want to multiple by $font-step
 *
 * $n   Any number of floating point number.
 *
 * Example: remCalc(4.5);
 * If the font rem size was set to 16px, this will produce: font-size: 1.5rem; (calculated 24px)
 * (4.5 * .33333 = 1.5)
 */
*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

html {
  font-size: 16px;
  font-size: calc(16px + 6 * ((100vw - 320px) / 448));
  scroll-behavior: smooth; }

body {
  background-color: #f7f7f2;
  color: #232323;
  font-family: heebo, serif;
  font-variation-settings: "wght" 300;
  line-height: 1.75;
  margin: 0;
  padding: 0; }
  body strong {
    background-color: rgba(174, 178, 44, 0.1); }

main {
  margin: 0 auto;
  max-width: 768px;
  padding: 10px; }

.g-recaptcha {
  display: none; }

.small-text {
  font-size: 0.66666rem; }

.italic-text {
  font-style: italic; }

h1, h2, h3, h4, h5, h6 {
  line-height: normal; }

a {
  color: #ff3900; }

ul {
  list-style-type: none;
  margin: 0; }
  ul li {
    position: relative; }
    ul li:after {
      background-color: #232323;
      border-radius: 4px;
      content: '';
      height: 4px;
      left: -1rem;
      position: absolute;
      top: 0.7rem;
      width: 4px; }

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)); }

@media (min-width: 48em) {
  html {
    font-size: 22px; } }

header {
  background-color: rgba(45, 108, 140, 0.85);
  color: #f7f7f2;
  margin: 0 auto;
  text-align: center; }
  header section {
    margin: 0 auto;
    max-width: 768px;
    position: relative; }
  header a {
    color: #f7f7f2;
    text-decoration: none; }
  header .brand {
    padding: 10px; }
    header .brand a {
      display: block;
      font-weight: 700; }
    header .brand .tagline {
      display: inline-block;
      font-size: .8rem;
      max-width: 360px; }
      header .brand .tagline span {
        border-bottom: 1px #f7f7f2 solid;
        display: inline-block; }
  header .nav-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: .5s ease-in-out;
    width: 28px;
    z-index: 101; }
    header .nav-toggle span {
      background-color: #f7f7f2;
      border-radius: 1px;
      display: inline-block;
      height: 2px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      width: 100%; }
    header .nav-toggle span:nth-child(1) {
      top: 0px; }
    header .nav-toggle span:nth-child(2),
    header .nav-toggle span:nth-child(3) {
      top: 10px; }
    header .nav-toggle span:nth-child(4) {
      top: 20px; }
    header .nav-toggle.open span:nth-child(1) {
      left: 50%;
      top: 10px;
      width: 0%; }
    header .nav-toggle.open span:nth-child(2) {
      background-color: #232323;
      transform: rotate(45deg); }
    header .nav-toggle.open span:nth-child(3) {
      background-color: #232323;
      transform: rotate(-45deg); }
    header .nav-toggle.open span:nth-child(4) {
      left: 50%;
      top: 10px;
      width: 0%; }
  header nav {
    height: 100vh;
    left: 0;
    overflow: scroll;
    padding: 0;
    position: absolute;
    text-align: center;
    transition: all .3s ease-out;
    top: 0;
    width: 0;
    z-index: 100; }
    header nav ul {
      list-style-type: none;
      margin: 0;
      padding: 50px 0;
      position: relative; }
      header nav ul li {
        margin: 5px 0;
        padding: 5px; }
        header nav ul li:after {
          display: none; }
    header nav.show-nav {
      background-color: #f7f7f2;
      width: 100vw; }
      header nav.show-nav ul li a {
        color: #232323;
        visibility: visible; }

/* 768 + */
@media (min-width: 768px) {
  header {
    text-align: initial; }
    header section {
      display: flex;
      justify-content: space-between; }
    header nav {
      height: auto;
      margin-right: 0;
      overflow: hidden;
      position: relative;
      width: auto; }
      header nav ul {
        text-align: right; }
        header nav ul li {
          display: inline-block; }
          header nav ul li a {
            visibility: visible; }
  .nav-toggle {
    display: none; } }

footer {
  background-color: rgba(174, 178, 44, 0.7);
  border-top: 4px #2d6c8c solid;
  padding: 1em 0;
  text-align: center; }
  footer ul {
    list-style-type: none;
    padding: 0; }
    footer ul li {
      display: inline-block;
      margin: 0 .5em; }
      footer ul li:after {
        display: none; }
  footer .social-icons {
    fill: #232323;
    height: 48px;
    width: auto; }

html {
  opacity: 1; }
