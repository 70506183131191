/**
 * FONTS
 */
@font-face {
  font-family: heebo;
  src: url('/app/ui/fonts/Heebo-VariableFont_wght.woff2') format('woff2-variations');
}

$normal         : 300;
$heading        : 400;
$rem-base       : 16px;     // Default font rem size
$font-step      : 0.33333;  // Font size if using remCalc()

/**
 * Colors
 */
$primary     : rgba(45, 108, 140, 1);   // #2D6C8C
$secondary   : rgba(174, 178, 44, 1);   // #AEB22C
$highlight   : rgba(255, 57, 0, 1);     // #FF3900
$light       : rgba(247, 247, 242, 1);  // #F7F7F2
$dark        : rgba(35, 35, 35, 1);     // #161925

@import "components/mixins";
@import "core";
@import "header";




@import "footer";

// Fix the FOUC. No JS required!
html {
  opacity: 1;
}
