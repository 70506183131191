header {
  background-color: rgba($primary, 0.85);
  color: $light;
  margin: 0 auto;
  text-align: center;
  section {
    margin: 0 auto;
    max-width: 768px;
    position: relative;
  }
  a {
    color: $light;
    text-decoration: none;
  }
  .brand {
    padding: 10px;
    a {
      display: block;
      font-weight: 700;
    }
    .tagline {
      display: inline-block;
      font-size: .8rem;
      max-width: 360px;
      span {
        border-bottom: 1px $light solid;
        display: inline-block;
      }
    }
  }
  .nav-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: .5s ease-in-out;
    width: 28px;
    z-index: 101;
    span {
      background-color: $light;
      border-radius: 1px;
      display: inline-block;
      height: 2px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      width: 100%;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2),
    span:nth-child(3) {
      top: 10px;
    }
    span:nth-child(4) {
      top: 20px;
    }
    &.open span:nth-child(1) {
      left: 50%;
      top: 10px;
      width: 0%;
    }
    &.open span:nth-child(2) {
      background-color: $dark;
      transform: rotate(45deg);
    }
    &.open span:nth-child(3) {
      background-color: $dark;
      transform: rotate(-45deg);
    }
    &.open span:nth-child(4) {
      left: 50%;
      top: 10px;
      width: 0%;
    }
  }
  nav {
    height: 100vh;
    left: 0;
    overflow: scroll;
    padding: 0;
    position: absolute;
    text-align: center;
    transition: all .3s ease-out;
    top: 0;
    width: 0;
    z-index: 100;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 50px 0;
      position: relative;
      li {
        margin: 5px 0;
        padding: 5px;
        &:after {
          display: none;
        }
      }
    }
    &.show-nav {
      background-color: $light;
      width: 100vw;
      ul {
        li a {
          color: $dark;
          visibility: visible;
        }
      }
    }
  }
}

/* 768 + */
@media (min-width: 768px) {
  header {
    text-align: initial;
    section {
      display: flex;
      justify-content: space-between;
    }
    nav {
      height: auto;
      margin-right: 0;
      overflow: hidden;
      position: relative;
      width: auto;
      ul {
        text-align: right;
        li {
          display: inline-block;
          a {
            visibility: visible;
          }
        }
      }
    }
  }
  .nav-toggle {
    display: none;
  }
}
