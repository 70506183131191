footer {
  background-color: rgba($secondary, 0.7);
  border-top: 4px $primary solid;
  padding: 1em 0;
  text-align: center;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 .5em;
      &:after {
        display: none;
      }
    }
  }
  .social-icons {
    fill: $dark;
    height: 48px;
    width: auto;
  }
}
